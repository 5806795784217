<template>
    <span
        class="pill"
        :class="`pill--${props.severity}`"
    >
        <slot />
    </span>
</template>

<script setup lang="ts">
    import type { Severity } from '~/types';

    export interface Props {
        severity: Severity;
    }

    const props = withDefaults(defineProps<Props>(), {});
</script>

<style lang="postcss">
    .pill {
        @apply inline-block px-3 py-1 rounded-md text-xs uppercase font-semibold whitespace-nowrap;

        &--danger {
            @apply bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100;
        }

        &--warning {
            @apply bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100;
        }

        &--success {
            @apply bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100;
        }

        &--info {
            @apply bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100;
        }

        &--gray {
            @apply bg-gray-200 text-black dark:bg-gray-500 dark:text-white;
        }
    }
</style>
